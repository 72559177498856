/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    code: "code",
    h3: "h3",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    pre: "pre"
  }, _provideComponents(), props.components), {Admonition} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  return React.createElement(React.Fragment, null, React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Event type:"), " processingAccount.funding.status.changed ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Version:"), " 1.0 ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Resource:"), " Boarding"), "\n", React.createElement(_components.p, null, "We trigger this event when we change the funding status of a processing account, for example, we stop funding the merchant if there is an issue with their bank account details on their funding account."), "\n", React.createElement(Admonition, {
    type: "default"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note:"), " When we change the funding status to ", React.createElement(_components.code, null, "disabled"), ", the merchant can still run transactions but won't receive the funds for the transactions.")), "\n", React.createElement(_components.h3, null, "Notification payload"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Attribute"), React.createElement(_components.th, {
    align: "left"
  }, "Description"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "processingAccountId")), React.createElement(_components.td, {
    align: "left"
  }, "Unique identifier that we assigned to the processing account.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "status")), React.createElement(_components.td, {
    align: "left"
  }, "Funding status of the processing account. The status is one of the following values: ", React.createElement("br"), React.createElement("br"), "• ", React.createElement(_components.strong, null, "enabled"), " – We send funds to the merchant. ", React.createElement("br"), React.createElement("br"), "• ", React.createElement(_components.strong, null, "disabled"), " – We don't send funds to the merchant, but the merchant can still run transactions.")))), "\n", React.createElement(_components.h3, null, "Example"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n  \"specversion\": \"1.0\",\n  \"type\": \"processingAccount.status.changed\",\n  \"version\": \"1.0\"\n  \"source\": \"payroc\",\n  \"id\": \"123e4567-e89b-12d3-a456-426614174000\",\n  \"time\": \"2024-05-12T12:34:56.789Z\",\n  \"datacontenttype\": \"application/json\",\n  \"data\": {\n    \"processingAccountId\": \"12345678\",\n    \"status\": \"enabled\"\n  }\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
